import { Chat } from '../types/Chat'
import { ChatPayload } from '../types/ChatPayload'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ChatApiInterface {
    getChats(): Promise<AxiosResponse<Chat[]>>
    getChatById(id: string): Promise<AxiosResponse<Chat>>
    getChatByCampaignId(id: string): Promise<AxiosResponse<Chat>>
    chat(payload: ChatPayload): Promise<AxiosResponse<Chat>>
    delete(id: string): Promise<AxiosResponse<void>>
}

export const ChatApi: ChatApiInterface = Object.freeze({
    getChats(): Promise<AxiosResponse<Chat[]>> {
        return ApiService.get('chats')
    },
    getChatById(id: string): Promise<AxiosResponse<Chat>> {
        return ApiService.get(`chats/${id}`)
    },
    getChatByCampaignId(id: string): Promise<AxiosResponse<Chat>> {
        return ApiService.get(`chats/campaigns/${id}`)
    },
    chat(payload: ChatPayload): Promise<AxiosResponse<Chat>> {
        return ApiService.post('chats', payload)
    },
    delete(id: string): Promise<AxiosResponse<void>> {
        return ApiService.delete(`chats/${id}`)
    },
})
