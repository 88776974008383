import React from 'react'
import { useTranslation } from 'react-i18next'

interface PreviousQuestionButtonProps {
    onClick: () => void
    disabled?: boolean
}

const PreviousQuestionButton = ({
    onClick,
    disabled,
}: PreviousQuestionButtonProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <button
            onClick={onClick}
            className="bg-[#ffffff] rounded-[10px] flex justify-center items-center w-[110px] px-[4px] sm:px-[6px] py-[4px] gap-3 disabled:opacity-60"
            disabled={disabled}
        >
            <svg
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity="0.8"
                    d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM3 10.5H2L2 13.5H3L3 10.5Z"
                    fill="#001F40"
                />
            </svg>
            <p className="text-[#001F40] text-sm sm:text-sm leading-3 font-lato font-bold">
                {t('common.back')}
            </p>
        </button>
    )
}

export default PreviousQuestionButton
